import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";

import { Column1of2, Columns2, ContentWrapper } from "../assets/styles/base";
import Layout from "../components/Layout";
import MetaComponents from "../components/seo/MetaComponents";
import GtmService from "../service/gtmService";

const Kontakt = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ContentWrapper hasMarginTop>
                <h1>{t("page.content.title")}</h1>
                <Columns2>
                    <Column1of2>
                        <h4>Miracl GmbH</h4>
                        <p>
                            <strong>
                                <Trans>page.content.address</Trans>
                            </strong>
                            :<br />
                            <a href="https://goo.gl/maps/mRRMQXk8gJhwdYBc9" target="_blank" rel="noreferrer">
                                Lindengasse 26/1/5
                                <br />
                                1070 Wien
                                <br />
                                Österreich
                            </a>
                        </p>
                        <p>
                            <strong>
                                <Trans>page.content.telephone</Trans>:
                            </strong>
                            <br />
                            <a
                                href="tel:+436703085171"
                                onClick={() => {
                                    GtmService.instance.trackEvent({
                                        event: "phoneClickedContactPage",
                                    });
                                }}
                            >
                                +43 670 3085171
                            </a>
                        </p>
                        <p>
                            <strong>
                                <Trans>page.content.email</Trans>:
                            </strong>
                            <br />
                            <a
                                href="mailto:kontakt@miracl.at"
                                onClick={() => {
                                    GtmService.instance.trackEvent({
                                        event: "contactClickedContactPage",
                                    });
                                }}
                            >
                                kontakt@miracl.at
                            </a>
                        </p>
                        <p>
                            <strong>Chat</strong>
                            <br />
                            <span
                                className="link"
                                onClick={() => {
                                    if (window.HubSpotConversations) {
                                        window.HubSpotConversations.widget.open();
                                    }
                                }}
                            >
                                Kontaktiere uns jezt!
                            </span>
                        </p>
                    </Column1of2>
                    <Column1of2>
                        <Map
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d664.8282341905846!2d16.351324!3d48.200588!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d078dcf5ceb4f%3A0xf8104ae49c80654!2sLindengasse%2026%2F1%205%2C%201070%20Wien!5e0!3m2!1sde!2sat!4v1658249952959!5m2!1sde!2sat"
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                        />
                    </Column1of2>
                </Columns2>
            </ContentWrapper>
        </Layout>
    );
};

const Map = styled.iframe`
    border: 0;
    height: 300px;
    width: 100%;
`;

export default Kontakt;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer", "page.kontakt"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
